import React, { useCallback, useMemo, useEffect, useState } from 'react';

import { BsPlus } from 'react-icons/bs';
import { BsThreeDotsVertical } from 'react-icons/bs';

import Lottie from 'react-lottie';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import { Form } from '@unform/web';
import { Container, Trade, Welcome, Modal } from './styles';
import WalletRow from '~/components/WalletRow';

import usdpi from '~/assets/logo/logo-usdpi.svg';
import usdt from '~/assets/logo/logo-usdt.svg';
import usdc from '~/assets/logo/logo-usdc.svg';
import dai from '~/assets/logo/token-dai.png';
import stakingTerms from '~/assets/animations/staking-terms-details.json';
import { formatPrice } from '~/utils/format';
import Table from '~/components/Table';
import Terms from './Terms';
import InputMask from '~/components/InputMask';
import { web3store } from '~/store';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

const logoMap: { [key: string]: string } = {
  usdt,
  usdpi,
  usdc,
  dai,
  default: usdt,
};

interface ITrending {
  id: number;
  name: string;
  percentage: number;
  img: string;
}

interface IPair {
  asset: string;
  stable: string;
}

interface ITrade {
  id: number;
  entry: number;
  exit: number;
  roi: number;
  roi_amt: number;
  fee_amt: number;
  ts: number;
  profit_loss: number;
  pair: IPair;
  txid: string;
  img: string;
}

interface IRollup {
  total_roi_today: number | null;
  total_roi_amt_today: number | null;
  total_roi_wtd: number | null;
  total_roi_amt_wtd: number | null;
  total_roi_mtd: number | null;
  total_roi_amt_mtd: number | null;
  total_roi_all_time: number | null;
  total_roi_amt_all_time: number | null;
}

const SmartTrade: React.FC = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [processText, setProcessText] = web3store.useState('processText');
  const [connectedMember, setConnectedMember] =
    web3store.useState('connectedMember');
  const [trending, setTrending] = useState<ITrending[]>([]);
  const [trades, setTrades] = useState<ITrade[]>([]);
  const [rollups, setRollups] = useState<IRollup | null>(null);
  const [balance, setBalance] = useState<number | null>(null);

  const [show, setShow] = useState(false);
  const [valueDeposit, setValueDeposit] = useState('');
  const [valueWithdraw, setValueWithdraw] = useState('');
  const [isDeposit, setIsDeposit] = useState(true);
  // const [showTerms, setShowTerms] = useState(true);
  const [showTerms, setShowTerms] = useState(false);
  const topTrades = useMemo(
    () => [
      {
        id: 1,
        name: 'USDT/SOL',
        percentage: +0.4,
        img: usdt,
      },
      {
        id: 2,
        name: 'UNI/USDC',
        percentage: +2.45,
        img: usdt,
      },
      {
        id: 3,
        name: 'WRX/USDT',
        percentage: -1.3,
        img: usdt,
      },
    ],
    []
  );

  const tradeColumns = useMemo(
    () => [
      {
        name: 'Trades',
        selector: 'trades',
        sortable: false,
        className: 'left-align',
      },
      {
        name: 'Entry',
        selector: 'entry',
        sortable: false,
      },
      {
        name: 'Exit',
        selector: 'exit',
        sortable: false,
      },
      {
        name: 'ROI',
        selector: 'roi',
        sortable: false,
      },
      {
        name: 'Profit/Loss',
        selector: 'profit_loss',
        sortable: false,
      },
      // {
      //   name: 'Date',
      //   selector: 'date',
      //   sortable: false,
      // },
      {
        name: 'Confirmation',
        selector: 'confirmation',
        sortable: false,
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        name: 'Trades',
        selector: 'trades',
        sortable: false,
      },
      {
        name: 'Entry',
        selector: 'entry',
        sortable: false,
      },
      {
        name: 'Exit',
        selector: 'exit',
        sortable: false,
      },
      {
        name: 'ROI',
        selector: 'roi',
        sortable: false,
      },
      {
        name: 'Profit/Loss',
        selector: 'profit_loss',
        sortable: false,
      },
      {
        name: 'Date',
        selector: 'date',
        sortable: false,
      },
      {
        name: 'Confirmation',
        selector: 'confirmation',
        sortable: false,
      },
    ],
    []
  );

  const abbreviateTxId = (txId: string, startChars = 3, endChars = 5) => {
    if (!txId) return '';
    return `${txId.substring(0, startChars)}...${txId.substring(
      txId.length - endChars
    )}`;
  };

  const handleTxClick = (fullTxId: string) => {
    // window.open(`https://solscan.io/tx/${fullTxId}`, '_blank');
    window.open(`https://bscscan.com/tx/${fullTxId}`, '_blank');
  };

  const formatToSixDecimalPlaces = (value: number) => {
    if (typeof value !== 'number') return value; // Return the value as is if it's not a number
    return value.toFixed(6); // Format to 6 decimal places
  };

  const formatToTwoDecimalPlaces = (
    value: number | string | null | undefined
  ): string => {
    if (typeof value === 'number') {
      return value.toFixed(2);
    }

    if (typeof value === 'string' && !Number.isNaN(parseFloat(value))) {
      return parseFloat(value).toFixed(2);
    }

    return 'N/A'; // No need for 'else' here
  };

  const tradeData = useMemo(
    () =>
      trades.map((trade) => ({
        trades: (
          <div>
            <img src={trade.img} alt="Logo" className="me-2" />
            {`${trade.pair.stable}/${trade.pair.asset}`}
          </div>
        ),
        entry: `$${formatToSixDecimalPlaces(trade.entry)}`,
        exit: `$${formatToSixDecimalPlaces(trade.exit)}`,
        roi: (
          <p
            className={`mb-0 d-flex align-items-center h6 fw-normal ${
              trade.roi >= 0 ? 'positive' : 'negative'
            }`}
          >
            <div
              className={`arrow-${
                trade.roi >= 0 ? 'positive' : 'negative'
              } me-2`}
            />
            {`${(trade.roi * 100).toFixed(4)}%`}
          </p>
        ),
        profit_loss: `${trade.profit_loss >= 0 ? '+' : ''}$${
          trade.profit_loss
        }`,
        date: new Date(trade.ts).toLocaleDateString(),
        // confirmation: abbreviateTxId(trade.txid),
        confirmation: (
          <button
            type="button"
            onClick={() => handleTxClick(trade.txid)}
            style={{
              cursor: 'pointer',
              color: '#438AFF',
              textDecoration: 'underline',
              border: 'none',
              background: 'none',
              padding: 0,
            }}
            title="Click to view full transaction details"
          >
            {abbreviateTxId(trade.txid)}
          </button>
        ),
      })),
    [trades]
  );

  const handleClose = useCallback(() => {
    setShow(false);
    setIsDeposit(true);
  }, []);

  const handleDepositChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueDeposit(event.target.value);
    },
    []
  );

  const handleWithdrawChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueWithdraw(event.target.value);
    },
    []
  );

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const parseFloatOrNull = (
    value: string | number | null | undefined
  ): number | null => {
    if (typeof value === 'string' && !Number.isNaN(parseFloat(value))) {
      return parseFloat(value);
    }
    return value !== null && value !== undefined ? Number(value) : null;
  };

  const fetchTraderDash = useCallback(() => {
    setProcessText('Loading subscription billing history');
    setLoading(true);
    api
      .get('v1/trader/dash', {
        params: {
          owner: connectedMember.memberId,
        },
      })
      .then(function (response) {
        console.log(response.data);
        setBalance(response.data.balance);

        const transformedTrending = response.data.trending.map(
          (trade: ITrending) => ({
            ...trade,
            img: logoMap[trade.img.toLowerCase()] || logoMap.default,
          })
        );
        setTrending(transformedTrending);
        // setTrending(response.data.trending);

        const transformedRecent = response.data.recent.map((trade: ITrade) => ({
          ...trade,
          img: logoMap[trade.pair.stable.toLowerCase()] || logoMap.default,
        }));
        // setTrades(response.data.recent);
        setTrades(transformedRecent);
        // setRollups(response.data.rollups);

        const parsedRollups: IRollup = {
          total_roi_today: parseFloatOrNull(
            response.data.rollups.total_roi_today
          ),
          total_roi_amt_today: parseFloatOrNull(
            response.data.rollups.total_roi_amt_today
          ),
          total_roi_wtd: parseFloatOrNull(response.data.rollups.total_roi_wtd),
          total_roi_amt_wtd: parseFloatOrNull(
            response.data.rollups.total_roi_amt_wtd
          ),
          total_roi_mtd: parseFloatOrNull(response.data.rollups.total_roi_mtd),
          total_roi_amt_mtd: parseFloatOrNull(
            response.data.rollups.total_roi_amt_mtd
          ),
          total_roi_all_time: parseFloatOrNull(
            response.data.rollups.total_roi_all_time
          ),
          total_roi_amt_all_time: parseFloatOrNull(
            response.data.rollups.total_roi_amt_all_time
          ),
        };
        setRollups(parsedRollups);
        setLoading(false);
      })
      .catch(function (error) {
        console.log('================= ERROR ===================');
        console.log(error);
      });
  }, [connectedMember]);

  useEffect(() => {
    // console.log('+++++ USER +++++');
    // console.log(user);
    if (user !== undefined) {
      fetchTraderDash();
      // fetchUserProfit();
      // fetchTrending();
      // fetchBalance();
    }
  }, [user]);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row justify-content-center">
          <div className="col-sm-11 col-lg-12 mb-2 mt-4">
            <Welcome>
              <div className="container-fluid">
                <div className="row align-items-center mb-4 pb-3">
                  <div className="col-lg-6 px-0">
                    <h1 className="h3 h2-lg font-weight-bold mb-lg-0">
                      <span>Smart</span>Trading
                    </h1>
                  </div>
                  <div className="col-lg-6 px-0">
                    <WalletRow />
                  </div>
                </div>
              </div>
            </Welcome>
            <Trade>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 col-xl-5 col-xxl-4">
                    <h2 className="text-white h3 fw-normal mb-3">
                      Your Wallet
                    </h2>
                    <div className="bg-graph p-4">
                      <div className="d-flex justify-content-between">
                        <img src={usdpi} alt="Usdpi" />
                        <button
                          type="button"
                          className="h6 mb-0 py-2 px-3 rounded-pill d-flex align-items-center"
                          onClick={() => setShow(true)}
                        >
                          <BsPlus size={24} color="#0D0D0D" />
                          Deposit / Withdraw
                        </button>
                      </div>
                      <div className="price mt-4">
                        <h3 className="h6">USDPI</h3>
                        {/* <span>${balance?.toString()}</span> */}
                        <span>
                          ${balance !== null ? formatPrice(balance) : 'N/A'}
                        </span>
                      </div>
                      <div className="mt-5 pt-5">
                        <h4 className="mb-0">
                          {rollups?.total_roi_all_time !== null &&
                          rollups?.total_roi_all_time !== undefined
                            ? `${(rollups.total_roi_all_time * 100).toFixed(
                                2
                              )}%`
                            : 'N/A'}
                          <span>All time</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4 col-xxl-6 top">
                    <h3 className="mt-3 mb-3">Today's Top Trades</h3>
                    <div className="top-trades p-4">
                      <h4 className="mb-3">🔥Trending</h4>
                      {trending.map((trade, index) => (
                        <div className="d-flex align-items-center mb-3 p-3 bg-coins">
                          <span className=" text-white h6 mb-0 fw-normal">
                            {index + 1}
                            {/* <img src={usdt} alt="Coin" className="mx-2" />{' '} */}
                            <img src={trade.img} alt="Coin" className="mx-2" />{' '}
                            {trade.name}
                          </span>
                          <span
                            className={`${
                              trade.percentage < 0 ? 'negative' : 'positive'
                            } h6 fw-normal mb-0 ms-auto`}
                          >
                            {trade.percentage}%
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-xl-3 col-xxl-2 times">
                    <div className="d-flex flex-column flex-lg-row flex-xl-column  justify-content-between pt-4 h-100 pb-3">
                      <div>
                        <h4>All time profit</h4>
                        <p className="d-flex align-items-center h6 fw-normal positive">
                          <div className="arrow-positive me-2" />
                          {rollups?.total_roi_all_time !== null &&
                          rollups?.total_roi_all_time !== undefined
                            ? `${(rollups.total_roi_all_time * 100).toFixed(
                                2
                              )}%`
                            : 'N/A'}
                          {' (+$'}
                          {formatToTwoDecimalPlaces(
                            rollups?.total_roi_amt_all_time
                          )}
                          )
                        </p>
                      </div>
                      <div>
                        <h4>Today’s profit</h4>
                        <p className="d-flex align-items-center h6 fw-normal positive">
                          <div className="arrow-positive me-2" />
                          {rollups?.total_roi_today !== null &&
                          rollups?.total_roi_today !== undefined
                            ? `${(rollups.total_roi_today * 100).toFixed(2)}%`
                            : 'N/A'}
                          {' (+$'}
                          {formatToTwoDecimalPlaces(
                            rollups?.total_roi_amt_today
                          )}
                          )
                        </p>
                      </div>
                      <div>
                        <h4>This week’s profit</h4>
                        <p className="d-flex align-items-center h6 fw-normal positive">
                          <div className="arrow-positive me-2" />
                          {rollups?.total_roi_wtd !== null &&
                          rollups?.total_roi_wtd !== undefined
                            ? `${(rollups.total_roi_wtd * 100).toFixed(2)}%`
                            : 'N/A'}
                          {' (+$'}
                          {formatToTwoDecimalPlaces(rollups?.total_roi_amt_wtd)}
                          )
                        </p>
                      </div>
                      <div>
                        <h4>This month’s profit</h4>
                        {/* <p className="d-flex align-items-center h6 fw-normal negative"> */}
                        <p className="d-flex align-items-center h6 fw-normal positive">
                          <div className="arrow-positive me-2" />
                          {rollups?.total_roi_mtd !== null &&
                          rollups?.total_roi_mtd !== undefined
                            ? `${(rollups.total_roi_mtd * 100).toFixed(2)}%`
                            : 'N/A'}
                          {' (+$'}
                          {formatToTwoDecimalPlaces(rollups?.total_roi_amt_mtd)}
                          )
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <h2 className="text-white h3 fw-normal mb-4 mt-4">
                      Recent Trades
                    </h2>
                    <Table
                      data={tradeData}
                      columns={tradeColumns}
                      className="table h-auto"
                    />
                  </div>
                </div>
              </div>
            </Trade>
          </div>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose} className="modal-terms">
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          <MdClose color="#e9e9e9" size={20} />
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pt-4">
          <div className="pe-none my-n5 d-flex align-items-center justify-content-center overflow-hidden">
            <Lottie
              options={{
                animationData: stakingTerms,
                autoplay: false,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={152}
              width={152}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-lg-5">
          <Form onSubmit={() => console.log('submit')}>
            <div className="texts w-100 mx-auto px-lg-3">
              <div className="btns-actions p-2 mb-3">
                <button
                  type="button"
                  className={isDeposit ? 'active' : ''}
                  onClick={() => setIsDeposit(true)}
                >
                  Deposit
                </button>
                <button
                  type="button"
                  className={!isDeposit ? 'active' : ''}
                  onClick={() => setIsDeposit(false)}
                >
                  Withdraw
                </button>
              </div>
              {isDeposit ? (
                <>
                  <div>
                    <InputMask
                      name="deposit"
                      kind="money"
                      options={{ unit: '$', separator: '.', delimiter: ',' }}
                      placeholder="$0.00"
                      className="input amt-needed-input"
                      value={valueDeposit}
                      onChange={handleDepositChange}
                    />
                  </div>
                  <div className="d-flex terms justify-content-between ps-5 mb-2">
                    <div className="d-flex justify-content-between align-items-center w-40 me-4">
                      <span>Need to buy USDPI?</span>
                    </div>
                    <button type="button" className="buy border-0">
                      Buy USDPI With Your Debit Card Here
                    </button>
                  </div>
                  <div className="d-flex terms justify-content-between py-2 px-5 mb-2">
                    <span>USDPI In Your Member Wallet:</span>
                    <span className="color-span">0.00 USDPI</span>
                  </div>
                  <div className="d-flex terms justify-content-between py-2 px-5 mb-2">
                    <span>USDPI In Your Metamask Wallet:</span>
                    <span className="color-span">0.00 USDPI</span>
                  </div>

                  <button type="button" className="btn-confirm w-100 my-4">
                    Deposit From Wallet{' '}
                    <HiArrowNarrowRight
                      className="ms-3"
                      color="#fff"
                      size={28}
                    />
                  </button>
                </>
              ) : (
                <>
                  <div>
                    <InputMask
                      name="withdraw"
                      kind="money"
                      options={{ unit: '$', separator: '.', delimiter: ',' }}
                      placeholder="$0.00"
                      className="input amt-needed-input"
                      value={valueWithdraw}
                      onChange={handleWithdrawChange}
                    />
                  </div>
                  <div className="d-flex terms justify-content-between py-2 px-5 mb-2 mt-5">
                    <span>Funds Will Be Withdrawn To Your Member Wallet:</span>
                    <span className="color-span">0.00 USDPI</span>
                  </div>
                  <button type="button" className="btn-confirm w-100 my-4">
                    Confirm Withdrawal{' '}
                    <HiArrowNarrowRight
                      className="ms-3"
                      color="#fff"
                      size={28}
                    />
                  </button>
                </>
              )}
              <button
                type="button"
                onClick={handleClose}
                className="btn-done w-100 mb-0"
              >
                <span className="">DONE</span>
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </Modal>
      <Terms show={showTerms} onClose={() => setShowTerms(false)} />
    </Container>
  );
};

export default SmartTrade;
